.doors-page {
    display: flex;
    flex-wrap: wrap;
    .order-configurator {
        flex: 0 1 66.66666667%;   
        padding: 32px;
        height: 100vh;
        overflow-y: auto;
        .stepper {
            margin-top: 32px;
            padding: 32px;
            background: #fff;
            box-shadow: 0 0 10px #e1e4e6;
            border-radius: 8px;
            .step {
                .d-flex {
                    .input-wrapper {
                        flex: 0 1 50%;
                    }
                }
            }
            .select-card {
                padding: 12px 24px;
                border-radius: 8px;
                border: solid 2px #ddd;
                cursor: pointer;
                &.color {
                    .color-circle {
                        width: 20px;
                        height: 20px;
                        border: solid 1px;
                        border-radius: 20px;
                        margin-right: 8px;
                    }
                }
                p {
                    margin: 0
                }
                &:hover {
                    border-color: #000;
                }
                &.selected {
                    border-color: #000;
                    background: #efefef;
                }
            }
            .yes-no-select {
                align-items: center;
                p {
                    margin: 0;
                }
                .select-card {
                    padding: 8px 16px;
                    min-width: 60px;
                    text-align: center;
                }
            }
            .buttons {
                justify-content: space-between;
            }
            .client-details-step .buttons .primary-button {
                margin-left: auto;
            }
        }
    }
    .order-summary {
        flex: 0 1 33.33333333%;
        padding: 32px;
        height: 100vh;
        background: #fff;
        overflow-y: auto;
    }
}