.order-schedule {
  -webkit-print-color-adjust:exact;
  .customer-input {
    display: flex;
  }
  .order-schedule-content {
    padding-top: 50px;
    .header, .items-row {
      display: flex;
      p {
        flex: 1 1 30%;
        display: flex;
        align-items: center;
      }
      p.delete-icon {
        flex: 0 0 10%;
        span {
          font-size: 13px;
          font-weight: bold;
          line-height: 1;
          cursor: pointer;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          background: #d2d2d2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .header p {
      color: #999;
    }
    .items-row  p {
      margin: 5px 0;
    }
  }
  .order-schedule-button {
    padding: 25px 0;
    padding-bottom: 50px;
    button {
      color: #fff;
      cursor: pointer;
      position: relative;
      -webkit-animation: all .25s ease-in-out;
      animation: all .25s ease-in-out;
      height: 45px;
      border: 0;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      padding: 0 20px;
      outline: none;
      &:disabled {
        cursor: initial;
        background: #999;
      }
    }
    button.configurator-button {
      background: #33a6ae;
    }
    button.reset-button {
      margin-left: 10px;
      background: #ae3333;
    }
    button.print-button {
      margin-left: 10px;
      background: #549a4f;
    }
  }
  .order-schedule-result {
    table {
      border-collapse: collapse;
      width: 100%;
      margin: 30px 0;
      table-layout: fixed;
    }
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    tr:nth-child(even) {
      background-color: #f0f0f0;
    }
  }
}

@page {
  margin: 30px;
}

@media print {
  body * {
    visibility: hidden;
  }

  #print-wrapper * {
    visibility: visible;
  }
  table {
    width: 100%;
    margin: 30px 0;
    table-layout: fixed;
  }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: #f0f0f0;
  }
}
