.door-size-step {
    .step-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .doors-table {
        .header, .row {
            display: flex;
            p {
                flex: 0 1 24%;
                &.actions {
                    flex: 0 1 4%;
                    cursor: pointer;
                }
            }
        }
    }
    .door-size-info {
        flex: 0 1 50%;
    }
}