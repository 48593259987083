.door-cut-list-step {
    padding-bottom: 16px;
    .order-details {
        margin-bottom: 32px;
    }
    .doors-table {
        .header, .row {
            display: flex;
            border-bottom: solid 1px;
            p {
                flex: 0 1 25%;
                margin: 8px 0;
            }
        }
    }
}