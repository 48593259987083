.shutters-page {
    .order-configurator,
    .order-schedule {
        flex: 1 1 50%;
        margin: 10px;
        padding: 30px;
        background: #fff;
        box-shadow: 0 0 10px #e1e4e6;
        border-radius: 8px;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .subtitle {
        font-size: 15px;
        color: #999;
        margin: 45px 0 20px 0;
    }
}