body {
  background: #f7f7f8;
}

div {
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

.gap-24 {
  gap: 24px;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-32 {
  margin-top: 32px;
}

.ml-auto {
  margin-left: auto;
}

.hidden-on-web {
  display: none;
}

@media print {
  .hidden-on-web {
    display: block;
  }
}

//FONTS
.f1 {
  font-size: 32px;
}

.f2 {
  font-size: 24px;
}

.f3 {
  font-size: 20px;
}

.f4 {
  font-size: 16px;
}

.f5 {
  font-size: 14px;
}

.content {
  display: flex;
  padding: 30px;
}

.font-bold {
  font-weight: bold;
}

.text-red {
  color: red;
}

.input-wrapper {
  margin-bottom: 16px;
}

.input-wrapper label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}

input, textarea {
  outline: 0;
  min-height: 40px;
  border: 0;
  border-radius: 8px;
  background-color: #f2f2f2;
  font-size: 14px;
  padding: 0 14px;
  width: 100%;
  box-sizing: border-box;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  cursor: pointer;
  position: relative;
  -webkit-animation: all .25s ease-in-out;
  animation: all .25s ease-in-out;
  height: 40px;
  min-width: 120px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 20px;
  outline: none;
}

.primary-button {
  color: #fff;
  border: 0;
  border-radius: 8px;
  background: #000;

  &:disabled {
    cursor: initial;
    background: #999;
  }
}

.secondary-button {
  color: #000;
  border: solid 2px;
  border-radius: 8px;
  background: #fff;

  &:disabled {
    cursor: initial;
    background: #999;
  }
}

@media (max-width: 992px) {
  .content {
    padding: 5px;
  }
}

@media (max-width: 767px) {
  .content {
    display: block;
  }

  .order-configurator,
  .order-schedule {
    padding: 25px;
  }
}