.start-page {
    // gap: 64px;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cards-wrapper {
        display: flex;
        gap: 48px;
        align-items: center;
        justify-content: center;
    }
    .select-card {
        cursor: pointer;
        min-width: 220px;
        background: #fff;
        box-shadow: 0 0 10px #e1e4e6;
        border-radius: 16px;
        padding: 64px;
        display: flex;
        justify-content: center;
    }
}